import { gql } from '@apollo/client';

export interface IUser {
  accountNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  userRoles: { role: string }[];
}

export interface IResponse {
  loggedInUser: IUser;
}

/**
 * local query against cache
 *
 * @see @link https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/
 */
export const GET_USER = gql`
  query user {
    loggedInUser @client {
      accountNumber
      cookieExpires
      firstName
      lastName
      email
      userRoles {
        role
      }
    }
  }
`;
