import type { Translations } from './de';

const translationsEN: Translations = {
  translation: {
    raps: 'RAPS',
    pageTitles: {
      home: '$t(raps) Recipes',
      search: '$t(raps) - Search',
      searchResult: '$t(raps) - Search results for: {{searchTerm}}',
      categories: '$t(raps) - Categories',
      category: '$t(raps) - Category: {{category}}',
      collections: '$t(raps) - Collections',
      collection: '$t(raps) - Collection: {{collection}}',
      managedCollection: '$t(raps) My Collection: {{collection}}',
      login: '$t(raps) - Login',
      faq: '$t(raps) - FAQ',
      contact: '$t(raps) - Contact',
      licenses: '$t(raps) - Licenses',
      imprint: '$t(raps) - Legal Notice',
      termsOfUse: '$t(raps) - Terms of Use',
      privacyPolicy: '$t(raps) - Privacy Policy',
      newsDetail: '$t(raps) - News: {{title}}',
      newsOverview: '$t(raps) - Latest News',
      newsCreate: '$t(raps) - Create News',
      newsEdit: '$t(raps) - Edit News: {{title}}',
      recipeCreate: '$t(raps) - Create Recipe',
      recipeOwn: '$t(raps) - My Recipes',
      recipeDetail: '$t(raps) - Recipe: {{title}} {{number}}',
      recipeEdit: '$t(raps) - Edit Recipe: {{title}} {{number}}',
      recipeCopy: '$t(raps) - Copy Recipe: {{title}} {{number}}',
      shop: '$t(raps) - Shop',
      shoppingCart: '$t(raps) - Shopping Cart',
      recipeSAPRecipes: '$t(raps) - Created RAPS Recipes',
      managePublications: '$t(raps) - Recipe Release',
      activate: '$t(raps) - Activate User',
      register: '$t(raps) - Register User',
      contactForm: '$t(raps) - Contact Form',
      forgotPassword: '$t(raps) - Forgot Password',
      resetPassword: '$t(raps) - Reset Password',
      error404: '$t(raps) - 404',
    },
    activate: {
      description: 'You can now log in',
      error: {
        description: 'Invalid activation link',
        message: 'Invalid activation link',
      },
      loading: 'Activating user…',
      message: 'User successfully activated',
    },
    anonymousUserNotice: {
      callToAction: 'Sign up now',
      notice: `Discover the variety of RAPS recipes – Use the full performance of the recipe platform and
      inspire your customers with new ideas!`,
    },
    brand: 'my<strong>Recipe</strong> | Recipe Platform',
    categories: {
      detailHeading: 'Category',
    },
    collections: {
      addNewRecipe: 'Create new recipe',
      addToRecipeCollection: 'Add to recipe collection',
      closeAndSaveModal: 'Save & Close',
      createNewCollection: 'Create recipe collection',
      deleteConfirmTitle: 'Do you really want to delete this collection?',
      detailHeading: 'Recipe Collection',
      editTitle: 'Edit collection name',
      heading: 'Recipe Collections',
      image: {
        change: {
          reset: {
            description:
              'The recipe image will be automatically selected again',
            message: 'Automatic recipe image selection activated!',
          },
          success: {
            description:
              'This recipe image has been set as the collection image.',
            message: 'Cover image selected!',
          },
        },
      },
      limitReached: `Maximum size reached. <br />Please note that a collection can only be assigned 100 recipes.`,
      new: {
        error: {
          description: `An error occurred while saving the recipe collection. A collection with this title may already exist.`,
          message: 'Could not save recipe collection',
        },
        form: {
          name: {
            placeholder: 'Title of the recipe collection',
            required:
              'The name of the recipe collection must be at least 3 characters long',
          },
        },
        success: {
          description: 'Recipe collection created',
          message: 'Recipe collection created',
        },
      },
      print: {
        fetchRecipesComplete:
          'Loading of recipes for collection print completed',
        fetchingFullRecipes: 'Loading full recipe data',
        generatingCollectionExport: 'Generating collection export',
        modalTitle: 'Print Collection',
        prepareRecipesComplete:
          'Preparation of recipes for collection print completed',
        preparingRecipes: 'Preparing recipes for export',
      },
      private: 'My Recipe Collections',
      public: 'RAPS Recipe Collections',
      publishAnonConfirmDescription:
        'After release, this collection will also be visible to non-logged-in users.',
      publishAnonTooltipContent: `This setting controls the visibility of the recipe collection to anonymous users.
      This setting only has an effect if the recipe collection is additionally released for all users.`,
      publishAnonTooltipTitle: 'Visibility for anonymous users',
      publishConfirmDescription:
        'After release, the collection will also be visible to RAPS customers.',
      publishConfirmTitle: 'Do you really want to release this collection?',
      publishGlobalTooltipContent: `This setting controls the visibility of the recipe collection to users of the RAPS
      Recipes Platform. Recipe collections that are not made visible can only be viewed by administrators.`,
      publishGlobalTooltipTitle: 'Visibility for all users',
      removePrompt:
        'Do you really want to remove this recipe from the collection?',
      removePromptTitle: 'Confirm deletion',
      retrievalFailed:
        'The list of recipes for this collection could not be retrieved',
      tabHeadings: {
        myCollections: 'My Recipe Collections',
        myRecipes: 'My Recipes',
        publicCollections: 'RAPS Recipe Collections',
        rapsRecipes: 'Created RAPS Recipes',
      },
      unpublishAnonConfirmDescription:
        'After confirmation, the collection will only be visible to logged-in users.',
      unpublishConfirmDescription:
        'After confirmation, the collection will only be visible to administrators.',
      unpublishConfirmTitle:
        'Do you want to revoke the release for this collection?',
    },
    common: {
      cancel: 'Cancel',
      categories: 'Categories',
      collections: 'Collections',
      decimalSeparator: '.',
      delete: 'Delete',
      add: 'Add',
      edit: 'Edit',
      remove: 'Remove',
      reset: 'Reset',
      update: 'Update',
      print: 'Print',
      noItems: 'No entries available',
      error: {
        fallback: {
          title: 'An unexpected error has occurred.',
          description:
            'If you continue to have this problem, please contact us.',
        },
        401: {
          anonymousUser: {
            description: 'Please log in to access this functionality.',
            message: 'Login required',
          },
          description: 'Please log in again.',
          message: 'Session expired',
        },
        403: {
          description: 'You do not have sufficient rights.',
          message: '403 Forbidden',
        },
        404: {
          anonymousUser: {
            directionText: 'Please go back to the',
            or: 'or to the',
            sorryText:
              'Oops – something went wrong or the page no longer exists!',
            startPage: 'homepage',
          },
          description: 'This page was not found',
          message: '404',
        },
        emailAlreadyInUse: 'This email is already in use',
        inputRequired: 'Please enter a valid value',
        invalidEmail: 'Please enter a valid email address',
        maxValue: '{{ name }} must be less than or equal to {{ value }}',
        minValue: '{{ name }} must be greater than or equal to {{ value }}',
        noNetwork: {
          description: `No data could be loaded. Please ensure you have an internet connection and try again.`,
          message: 'Network error',
        },
        numberRequired: '{{ name }} must be a number',
        selectFromOptions: 'Please select from the available options.',
        unknown: {
          description:
            'An unknown error has occurred, retrying the action might help.',
          message: 'Unknown error',
        },
      },
      goBack: 'Go Back',
      loadMore: 'Load More',
      logout: 'Logout',
      myRecipes: 'My Recipes',
      news: 'Latest Topics',
      no: 'No',
      noImage: 'No image available',
      privacyPolicy: 'Privacy Policy',
      recipePlatform: 'Recipe Platform',
      sapRecipes: 'Created RAPS Recipes',
      save: 'Save',
      search: 'Search',
      shop: 'Shop',
      yes: 'Yes',
    },
    contact: {
      title: 'Contact',
    },
    faq: {
      error: 'FAQ list cannot be loaded.',
      longTitle: 'Frequently Asked Questions (FAQ)',
      shortTitle: 'FAQ',
    },
    forgotPassword: {
      description: `If there is a user account associated with this email address, we have sent an email with a link
      to reset your password. This link is valid for 24 hours after receipt.`,
      email: 'Email',
      message: 'Email sent!',
      resetPassword: {
        description: 'Password change successful!',
        message: 'Password changed successfully',
        password: 'New password',
        passwordInvalid: 'The password must be at least 8 characters long',
        passwordRepeat: 'Confirm new password',
        repeatPassword: 'Confirm password',
        repeatPasswordWrong: 'The passwords do not match',
      },
      title: 'Forgot password?',
    },
    header: {
      login: 'Login',
      register: 'Register',
    },
    imprint: {
      title: 'Legal Notice',
    },
    ingredient: {
      add: 'Add ingredient',
      declaration: 'Declaration',
      declarationPlaceholder:
        'e.g., Puff pastry (WHEAT flour, butter (MILK and MILK PRODUCTS), salt, sugar)',
      modal: {
        RAPSIngredients: 'RAPS Ingredients',
        allIngredients: 'All Ingredients',
        or: 'OR',
        otherIngredients: 'Other Ingredients',
        quidIngredients: 'QUID Fl. Ingredients',
        searchHint: 'Start typing to search for ingredients...',
        searchNotFoundHint: 'No ingredients found. Try different keywords.',
        searchPlaceHolder: 'Search ingredients...',
      },
      new: 'Create new ingredient',
      newPlaceholder: 'Ingredient name',
      noIngredientsYet: 'No ingredients yet',
      search: 'Search for ingredients',
      shoppingCart: 'Shopping Cart',
    },
    licenses: {
      error: 'An error occurred',
      link: 'here',
      loading: 'Loading licenses',
      title: 'Licenses',
    },
    login: {
      email: {
        error: 'Please enter your email address',
        placeholder: 'Email',
      },
      error: {
        description: 'Please check your login credentials and try again.',
        message: 'Login failed',
      },
      forgotPasswordLink: 'Forgot password?',
      loginButton: 'Login',
      password: {
        error: 'Please enter your password',
        placeholder: 'Password',
      },
      registerLink: 'Or register now!',
    },
    modal: {
      cancel: 'Cancel',
      ok: 'Confirm',
    },
    news: {
      content: 'Content',
      createNews: 'Create news',
      deleteConfirmNews: 'Do you really want to delete this news?',
      deleteImageContent: 'Do you really want to delete this image?',
      deleteImageTitle: 'Delete image',
      editNews: 'Edit news',
      moreNews: 'More News',
      readButton: 'Read',
      subtitle: 'Subtitle',
      title: 'Title',
    },
    nutritionalInformation: {
      LRA_NUTRITIONS_FAT: 'Fat',
      LRA_NUTRITIONS_SALT: 'Salt',
      LRA_NUTRITIONS_SATURATED_FA: 'Of which saturated fatty acids',
      LRA_NUTRTIONS_CARBOHYDRATES: 'Carbohydrates',
      LRA_NUTRTIONS_KCAL: 'Energy',
      LRA_NUTRTIONS_KJ: 'Energy',
      LRA_NUTRTIONS_PROTEINE: 'Protein',
      LRA_NUTRTIONS_SUGARS: 'Of which sugars',
      header: 'Nutritional values per 100 g',
      weightLossInfo: {
        description: 'Nutritional information',
        message: 'Nutritional values',
      },
    },
    overview: 'Overview',
    privacyPolicy: {
      gaOptOut: {
        description: `You can prevent the collection of your data by Google Analytics by clicking on the following
        link. An opt-out cookie will be set to prevent your data from being collected on future visits to
        myRecipe:`,
        disableGA: 'Disable Google Analytics',
        message: 'Opt-out from Google Services',
        notification: {
          disabledGAMessage: 'Google Analytics disabled',
          status: {
            gaDisabledStatus:
              'Currently, no data is being collected by Google Analytics. You have successfully opted out.',
            gaEnabledStatus:
              'Currently, data is being collected by Google Analytics.',
            title: 'Status: ',
          },
        },
      },
      title: 'Privacy Policy',
    },
    publishing: {
      tabHeadings: {
        revoked: 'Release revoked',
        unpublished: 'Release pending',
      },
      title: 'Recipe Release',
    },
    recipe: {
      label: 'Recipe',
      addButton: 'Create',
      additiveMaterialHeading: 'Additive Material',
      author: 'Author',
      baseMaterialHeading: 'Base Material',
      baseWeightLabel: 'Total Material',
      baseWeight: '{{weight}} $t(recipe.baseWeightLabel)',
      baseWeightNewBaseline: 'The ingredient amount has been recalculated',
      baseWeightRecalculated: 'Total material recalculated',
      casing: 'Sausage casings/Natural casing',
      categories: 'Categories',
      categories_plural: 'Categories',
      closeAndSaveModal: 'Save & Close',
      cooking: 'Preparation',
      copyButton: 'Copy',
      copyPrefix: 'Copy of',
      copyToClipboard: 'Copy to clipboard',
      copyToClipboardSuccess: {
        description:
          'The declaration recommendation has been copied to the clipboard',
        message: 'Copy successful',
      },
      creation: 'Production',
      custom: 'Custom Recipes',
      declaration: {
        hints: {
          allergens: '* Declaration and allergens to be completed.',
          venison:
            '** The meat used must be designated according to the type of animal used.',
        },
        fatType: {
          H: 'Chicken fat',
          K: 'Veal fat',
          L: 'Lamb fat',
          P: 'Turkey fat',
          R: 'Beef fat',
          S: 'Bacon',
          W: 'Game fat',
        },
        loading: 'Loading QUID calculation...',
        meatType: {
          H: 'Chicken',
          K: 'Veal',
          L: 'Lamb',
          P: 'Turkey',
          R: 'Beef',
          S: 'Pork',
          W: 'Game',
        },
        text: {
          and: 'and',
          meat: 'meat',
          weightLossHint:
            '100 g of the product were made from {{ amount }} g of {{ meat }}.',
        },
      },
      deleteButton: 'Delete',
      deleteConfirmTitle: 'Do you really want to delete this recipe?',
      deleteImageContent: 'Do you really want to delete this recipe image?',
      deleteImageTitle: 'Delete image',
      editButton: 'Edit',
      emailButton: 'Transfer',
      emailLastSent: 'Last sent on {{ date }} by {{ user }}',
      emailSent: 'Recipe sent',
      emailSuccess: {
        description: 'The recipe was successfully sent by email.',
        message: 'Recipe sent',
      },
      info: 'Information',
      ingredient: 'Ingredient',
      ingredientNoLongerAvailable: 'This ingredient is no longer available.',
      ingredients: 'Ingredients',
      invisibleToAnonUser: 'This recipe is not visible to anonymous users',
      lastUpdate: 'Status',
      miscellaneous: 'Miscellaneous',
      number: 'Recipe No.',
      nutrientInformation: 'Nutritional information',
      packaging: 'Packaging',
      printButton: 'Print',
      provideAllergenicSubstances:
        '* Declaration and allergens to be completed.',
      publishAnonConfirmDescription:
        'After confirmation, this recipe will also be visible to non-logged-in users (if it has been released).',
      publishAnonDescription:
        'This setting only affects recipes that have already been released.',
      publishConfirmDescription:
        'After release, the recipe will also be visible to RAPS customers.',
      publishConfirmTitle: 'Do you really want to release this recipe?',
      publishedBy: 'Released by {{name}}',
      publishedByDetail: 'Release occurred on {{date}}',
      quantity: 'Quantity',
      rapsIngredients: 'RAPS Ingredients',
      recommendedDeclaration: 'Non-binding declaration recommendation',
      relativeToBaseWeightDefault: 'Based on the total material',
      relativeToTotalWeightDefault: 'Based on the total amount',
      sap: 'SAP',
      sapDescription: 'This recipe is only for import into SAP.',
      supplementaryIngredients: 'Addition',
      totalWeightLabel: 'Total amount',
      totalWeight: '{{weight}} $t(recipe.totalWeightLabel)',
      totalWeightRecalculated: 'Total amount recalculated',
      unpublishAnonConfirmDescription:
        'After confirmation, the recipe will only be visible to logged-in users (if it has been released).',
      unpublishConfirmDescription:
        'After confirmation, the recipe will only be visible to administrators.',
      unpublishConfirmTitle:
        'Do you want to revoke the release for this recipe?',
      unpublishedBy: 'Release withdrawn by {{name}}',
      unpublishedByDetail: 'Withdrawal occurred on {{date}}',
      unpublishedYet: 'This recipe has not yet been released',
      uploadImageMaxSizeHint: 'The file may be a maximum of 2MB in size',
      uploadImageText: 'Click or drag the file to this area to upload',
      uploadImageTitle: 'Upload new image',
      virtualIngredients: 'Other Ingredients',
      visibleToAnonUser: 'This recipe is visible to anonymous users',
    },

    recipeManagement: {
      title: 'Created RAPS Recipes',
    },
    recipes: {
      baseMaterial: 'Base Material',
      inlayMaterial: 'Inlay Material',
      ingredientExplanation: `The ingredients for a meat product can generally be divided into three areas: base material (e.g., meat, fat, and ice), inlay material (e.g., mushrooms in a mushroom Lyoner), and additions (e.g., spices). In the "Total Material" view of a recipe, the ingredients are divided into these three areas, and the quantity calculation is based on the base and inlay materials.<br />Please check this box if the ingredient belongs to the {{material}}.`,
      categories: {
        error: 'Categories could not be loaded',
        placeholder: 'Select categories',
        title: 'Categories',
      },
      createNewRecipe: 'Create recipe',
      declarationExplanation: `If an ingredient is used in a recipe, it must be listed in the recipe's ingredient list. Please indicate here how the ingredient should appear in the ingredient list of a recipe. <br />For ingredients consisting of only one component, simply use the name of the ingredient (e.g., "potatoes" compare product label). For ingredients consisting of several components, the trade name must be followed by all contained components in parentheses in descending order (e.g., "pizza dough (WHEAT flour, olive oil, salt)"), a good source of information for this is the specification of your supplier. In any case, allergens must be clearly marked by CAPITALIZATION (e.g., "WHEAT flour" or "Butter (including MILK)").`,
      editRecipe: 'Edit recipe',
      editSAPRecipe: 'Edit RAPS recipe',
      error: {
        invalidIngredients: {
          description:
            'Please ensure that all ingredients are selected from the provided options.',
          message: 'Some ingredients are invalid',
          undefinedId: 'Invalid ingredient ID.',
        },
        noDuplicates: {
          description: 'Duplicate assignment of an ingredient',
          message: 'Multiple assignments of an ingredient',
        },
        noIngredients: {
          description: 'Too few ingredients',
          message: 'Too few ingredients',
        },
        noTitle: 'Title must not be empty!',
        save: 'Save failed',
      },
      explanationQuidMeatCalculation: {
        content: `The proportions of contained meat and fat are essential quality characteristics of a meat product. If the fat content in the meat exceeds a certain percentage, the fat must be listed separately in the ingredient list. To determine the meat and fat proportions as well as the labeling requirement for fat, the so-called QUID meat calculation is performed.<br />Please check this box if the QUID meat calculation should be carried out for this recipe. The calculation of the ingredient list is then done automatically. Please note that only the raw materials included in the RAPS meat sorting and the RAPS processing standards are included in the QUID meat calculation.`,
        title: 'What is the QUID meat calculation?',
      },
      explanationWeightLoss: {
        content: `In the manufacturing process, meat products can lose weight due to drying when the contained water evaporates (e.g., in the production of salami).<br />Please indicate in this field the percentage weight loss due to drying if such occurs in this recipe. The weight loss is automatically taken into account in the calculation of the nutritional values and in a possible QUID meat calculation.`,
        title: 'What does weight loss mean?',
      },
      isAdditiveIngredient: 'Part of the inlay material?',
      isBaseIngredient: 'Part of the base material?',
      isQuid: 'Subject to QUID labeling?',
      keyValueInfoModal: {
        H_1: {
          key: 'H 1',
          value:
            'chicken meat without fat tissue and tendons, without visible fat and skin',
        },
        H_2: {
          key: 'H 2',
          value:
            'chicken meat with fat and tendons, with 10% visible fat and skin',
        },
        H_3: { key: 'H 3', value: 'marbled chicken meat' },
        H_4: {
          key: 'H 4',
          value: 'chicken separator meat with fat and skin',
        },
        H_5: { key: 'H 5', value: 'chicken skin with fat' },
        K_1: {
          key: 'K 1',
          value: 'veal without fat tissue and tendons, without visible fat',
        },
        K_2: {
          key: 'K 2',
          value: 'veal with low tendons, with 5% visible fat',
        },
        K_3: {
          key: 'K 3',
          value: 'roughly deboned veal with 10% visible fat',
        },
        K_4: {
          key: 'K 4',
          value: 'tendon-free veal with 20% visible fat',
        },
        L_1: {
          key: 'L 1',
          value:
            'lamb meat without fat tissue and tendons, without visible fat',
        },
        L_2: {
          key: 'L 2',
          value: 'lamb meat with low tendons, with 5% visible fat',
        },
        L_3: {
          key: 'L 3',
          value: 'roughly deboned lamb meat with 10% visible fat',
        },
        L_4: {
          key: 'L 4',
          value: 'tendon-free lamb meat with 30% visible fat',
        },
        P_1: {
          key: 'P 1',
          value:
            'turkey meat without fat tissue and tendons, without visible fat and skin',
        },
        P_2: {
          key: 'P 2',
          value:
            'turkey meat with low tendons, with 5% visible fat without skin',
        },
        P_3: {
          key: 'P 3',
          value:
            'turkey meat with fat and tendons, with 10% visible fat and skin',
        },
        P_4: { key: 'P 4', value: 'marbled turkey drumstick' },
        P_5: { key: 'P 5', value: 'turkey skin with fat' },
        R_1: {
          key: 'R 1',
          value: 'beef without fat tissue and tendons, without visible fat',
        },
        R_2: {
          key: 'R 2',
          value: 'beef with low tendons, with 5% visible fat',
        },
        R_3: {
          key: 'R 3',
          value: 'roughly deboned beef with 10% visible fat',
        },
        R_4: {
          key: 'R 4',
          value: 'beef with fat and tendons, with 15% visible fat',
        },
        R_5: {
          key: 'R 5',
          value: 'tendon-free beef with 30% visible fat',
        },
        R_6: { key: 'R 6', value: 'beef fat' },
        S_1: {
          key: 'S 1',
          value: 'pork without fat tissue and tendons, without visible fat',
        },
        S_10: { key: 'S 10', value: 'medium-fat flanks' },
        S_11: { key: 'S 11', value: 'fat flanks' },
        S_2: {
          key: 'S 2',
          value: 'tendon-free pork with 5% visible fat',
        },
        S_3: {
          key: 'S 3',
          value: 'roughly defatted pork with 10% visible fat',
        },
        S_4: {
          key: 'S 4',
          value: 'less fatty belly meat with 25% visible fat',
        },
        S_5: {
          key: 'S 5',
          value: 'fatty belly meat with 60% visible fat',
        },
        S_6: { key: 'S 6', value: 'fat cheek' },
        S_7: { key: 'S 7', value: 'neck fat' },
        S_8: { key: 'S 8', value: 'back fat' },
        S_9: { key: 'S 9', value: 'fat trimmings' },
        UNKNOWN_1: { key: '', value: 'pork neck' },
        UNKNOWN_2: { key: '', value: 'pork shoulder' },
        UNKNOWN_3: {
          key: '',
          value: 'cured, cooked pork fat cheek',
        },
        W_1: {
          key: 'W 1',
          value:
            'game meat without fat tissue and tendons, without visible fat',
        },
        W_2: {
          key: 'W 2',
          value: 'game meat with low tendons, with 5% visible fat',
        },
        W_3: {
          key: 'W 3',
          value: 'roughly deboned game meat with 10% visible fat',
        },
        titleKey: 'ABBREVIATION',
        titleModal: 'Overview',
        titleValue: 'DESCRIPTION',
      },
      meatProductSelection: 'Meat Product',
      new: 'Create new recipe',
      newSAP: 'Create new RAPS recipe',
      newestRecipesHeading: 'Latest Recipes',
      noNewestRecipesAvailable: 'To see the latest recipes, please log in.',
      quidExplanation: `<strong>Quantitative Ingredients Declaration</strong> (Quantitative declaration of ingredients according to the Food Information Regulation (EU) No. 1169/2011, Article 22 and Annex VIII). In some cases, according to the so-called QUID labeling requirement, it is necessary to indicate the proportion of an ingredient in a recipe in the ingredient list in percent. This is the case when the customer is conveyed that a certain ingredient is contained in a recipe (e.g., by the name, words on the packaging, or an associated image), or the ingredient is essential for characterizing a recipe. Examples include paprika in a recipe called Paprika sausages; mushrooms, if they are clearly visible on the recipe image, or eggs in sponge cake. Fact sheets with more detailed information on QUID labeling can be obtained from the websites of the state examination offices.<br />Please check this box if labeling according to the QUID regulations is necessary.`,
      quidMeatCalculation: 'QUID Meat Calculation',
      title: 'Title',
      titlePlaceholder: 'Recipe title',
      weightLoss: 'Weight Loss',
      whatIsADeclaration: 'What is a declaration?',
      whatIsAdditiveIngredient: 'What is the inlay material?',
      whatIsBaseIngredient: 'What is the base material?',
      whatIsQuid: 'What is QUID labeling?',
      zan1: 'Manufacturing',
      zan1Placeholder: 'Describe the manufacturing of the dish here.',
      zan2: 'Preparation',
      zan2Placeholder:
        'Describe how your dish is prepared for consumption here.',
      zan3: 'Packaging',
      zan3Placeholder: 'Describe packaging instructions here',
      zan4: 'Sausage casings/Natural casing',
      zan4Placeholder:
        'Describe instructions for sausage casings or natural casing here',
      zan5: 'Miscellaneous',
      zan5Placeholder: 'Other notes can be placed here',
    },
    register: {
      acceptConditions: 'I have read and accept the terms and conditions',
      accountNumber: 'RAPS Customer Number',
      companyName: 'Company',
      conditionsNotAccepted: 'Terms and conditions must be accepted',
      contactForm: {
        city: 'City',
        contactPerson: 'Contact Person',
        houseNumber: 'House Number',
        infoBox: {
          description:
            'Registration is exclusively for commercial customers. <0>Not a commercial customer?</0>',
          message: 'Customer Information',
        },
        infoModal: {
          customerService:
            'You can also order products as an end consumer via our Customer Service by phone: <0>0800 / 439 83 79</0>',
          intro:
            'This recipe platform is fully accessible exclusively to commercial customers.',
          shop: 'Or visit us in Kulmbach and buy spices in our <0>Apprentice Store “RAPSODY OF SPICES”</0>',
        },
        street: 'Street',
        submit: 'Submit',
        success: {
          description:
            'Your details have been transmitted to our customer service. We will contact you promptly.',
          message: 'Thank you!',
        },
        title: 'Contact Form for New Customers',
        zip: 'Postal Code',
      },
      email: 'Email',
      error: {
        backend: {
          alreadyRegistered: 'This user is already registered',
          email: 'This email address has already been used',
          invalidCustomerOrZipCode: {
            description: `The combination of customer number and postal code is unknown! For further inquiries, please contact 
    our customer support by email <0>(bestellservice@raps.de)</0> or phone <1>(0800 439 83 79)</1>.`,
            errorDescription:
              'The combination of customer number and postal code is unknown!',
            errorDescriptionCustomerService:
              'The combination of customer number and postal code is either unknown or already in use.',
            message: 'Invalid combination!',
          },
        },
        message: 'Registration failed',
      },
      firstName: 'First Name',
      lastName: 'Last Name',
      newCustomerDescription:
        'If you are a new customer at RAPS, please use the <0>new customer form</0> to contact our customer service.',
      newCustomerMessage: 'New customer at RAPS?',
      password: 'Password',
      passwordInvalid: 'The password must be at least 8 characters long',
      repeatPassword: 'Confirm password',
      repeatPasswordWrong: 'The passwords do not match',
      submit: 'Register',
      success: {
        description:
          'You have successfully registered. A confirmation email has been sent to your email address.',
        message: 'Registration successful!',
      },
      title: 'Register',
      zipCode: 'Postal Code',
    },
    search: {
      action: 'Search',
      for: 'for',
      loadPage: 'Load page',
      noResults: 'No entries available',
      noResultsFor: 'No results found for "<strong>{{searchTerm}}</strong>"',
      placeholder:
        'Recipe title (e.g., Salad), recipe number (e.g., 5503167) or ingredient number (e.g., 1000692)',
      recipe_one: '{{count}} Recipe',
      recipe_other: '{{count}} Recipes',
      searchFor: 'Search for',
      showMultiResultInfo:
        'Showing results {{pageStart}}-{{pageEnd}} of {{total}}',
      showSingleResultInfo: 'Showing result {{num}} of {{total}}',
      showAllResults: 'Show all results',
      tabHeadings: {
        categories: 'Categories',
        ingredients: 'Ingredients',
        myCollections: 'My Recipe Collections',
        privateRecipes: 'My Recipes',
        publicCollections: 'RAPS Recipe Collections',
        rapsRecipes: 'RAPS Recipes',
        rapsSAPRecipes: 'Created RAPS Recipes',
        recipes: 'Recipes',
      },
    },

    shop: {
      alreadyInShoppingCart: 'This product is already in your shopping cart',
      choseCategory: 'Choose a category',
      columns: {
        container: 'Container',
        containerSize: 'Container Size',
        foodName: 'Trade Name',
        name: 'Name',
        number: 'Article Number',
      },
      explanationContainerSize: {
        content: 'Available container sizes: {{ containerSizes }}',
        title: 'Container Size',
      },
      noDataContent: 'No data available for the selected category',
      noDataHeader: 'No data available.',
      selectPlaceholder: 'Select a category',
    },
    shoppingCart: {
      addToBasket: 'Add ingredients to the shopping cart',
      addToBasketRequiresUser:
        'Adding ingredients to the shopping cart is only possible as a logged-in user',
      addToBasketSuccess: {
        description:
          'The ingredients were successfully added to the shopping cart.',
        message: 'Shopping cart updated',
      },
      agb: {
        message: 'read and accepted',
        name: 'Terms and Conditions',
        notAcceptedError: 'Please accept our terms and conditions',
      },
      amount: 'Quantity',
      createdOrder: 'Order was created',
      duplicateContainers: {
        description:
          'It is not allowed to use the same container size multiple times.',
        message: 'Container size already used',
      },
      emptyButton: 'Empty shopping cart',
      emptyConfirm: 'Do you really want to empty the shopping cart?',
      emptyMessage:
        'The shopping cart is empty. You can add RAPS ingredients to the shopping cart via a recipe',
      messagePlaceholder: 'Your message to us!',
      needed: 'Needed',
      orderButton: 'Order',
      sum: 'Total',
      table: {
        ingredient: 'Product',
        quantity: 'Amount',
      },
      title: 'Shopping Cart',
      units: {
        KG: 'kg',
      },
      warning: `The order is binding and is based on the customer-specific prices agreed with you.
      For new customers, the list prices valid at the time of the order apply. In the event that discount levels apply depending on the
      quantity, these will be taken into account accordingly.`,
    },
    termsOfUse: {
      licenses: {
        text: 'You can find our third-party licenses ',
        title: 'Used Licenses',
      },
      title: 'Legal Notices',
    },
    update: {
      button: 'Update',
      description: 'Click "Update" to view the new version:',
      message: 'Update available',
    },
    userManagement: {
      accountNumber: 'Customer Number',
      actions: 'Actions',
      changeEmailDisabled:
        'The email address can only be changed if the respective user has been assigned a customer number.',
      collectionEditor: 'Management of RAPS recipe collections',
      confirmDeleteMessage:
        'Do you really want to delete the user with the customer number {{accountNumber}}?',
      editMailButton: 'Change email',
      editUserEmail:
        'Change email address for customer number {{accountNumber}}',
      email: 'Email',
      newEmail: 'Enter new email address',
      newsAuthor: 'News Author',
      recipeCreator: 'Creation of RAPS recipes',
      reviewer: 'Management of recipes',
      roles: 'Permissions',
      sameMail:
        'The email address is identical to the current email address of this user',
      selectRole: 'Select user roles',
      title: 'User Management',
    },
    userRequiredInfo: {
      button: 'Ok',
      message: 'This functionality is only available to logged-in users.',
    },
  },
};

export default translationsEN;
