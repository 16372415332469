import React, { FunctionComponent, useCallback } from 'react';
import styled from '@emotion/styled';
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthGuard } from '../../../../../components/AuthGuard';
import { DeleteButton } from '../../../../../components/DeleteButton';
import { BreakableTitle } from '../../../../../components/BreakableTitle';
import { UserRequiredInfo } from '../../../../../components/UserRequiredInfo';
import {
  DELETE_RECIPE,
  IContext as IDeleteRecipeContext,
  IVariables as IDeleteRecipeVariables,
} from '../../../../../gql/delete-recipe';
import { IRecipe } from '../../../../../gql/get-recipe';
import { handleError } from '../../../../../lib/handleError';
import {
  defaultActionButtonMargin,
  defaultGutterPixelSize,
} from '../../../../../lib/styles';
import { roles } from '../../../../../lib/userRoles';
import { PrintButton } from './Buttons/PrintButton';
import { MailButton } from './Buttons/MailButton';
import { CopyButton } from './Buttons/CopyButton';
import { EditButton } from './Buttons/EditButton';

const ActionCol = styled(Col)`
  ${defaultActionButtonMargin};
`;

interface IProps {
  recipe: IRecipe;
}

export const RecipeTitle: FunctionComponent<IProps> = ({ recipe }) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleConfirmDelete = useCallback(async () => {
    try {
      const response = await client.mutate<
        IDeleteRecipeContext,
        IDeleteRecipeVariables
      >({
        mutation: DELETE_RECIPE,
        variables: {
          input: {
            id: recipe.id,
          },
        },
      });

      if (!response.errors) {
        navigate(recipe.isSAP ? '/manage-recipes' : '/recipes/own');
      }
    } catch (e) {
      handleError(e);
    }
  }, [client, navigate, recipe.id, recipe.isSAP]);

  return (
    <Row gutter={defaultGutterPixelSize} justify="space-between">
      <Col style={{ maxWidth: '100%' }}>
        <BreakableTitle>{recipe.title}</BreakableTitle>
      </Col>
      <ActionCol>
        <AuthGuard>
          {(isGranted) =>
            isGranted ? (
              <Space wrap>
                {(recipe.user !== null || recipe.isSAP) && (
                  <CopyButton name={recipe.title} />
                )}
                {!recipe.isSAP && <EditButton recipe={recipe} />}
                {(recipe.user !== null || recipe.isSAP) && (
                  <DeleteButton
                    circle={false}
                    modalTitle={t('recipe.deleteConfirmTitle')}
                    onConfirmDelete={handleConfirmDelete}
                  />
                )}
                <PrintButton recipe={recipe} />

                <AuthGuard roles={[roles.creator]}>
                  {recipe.user !== null && <MailButton recipe={recipe} />}
                </AuthGuard>
              </Space>
            ) : (
              <UserRequiredInfo cursor="pointer">
                <Space wrap>
                  <Button icon={<EditOutlined />}>
                    {t('recipe.editButton')}
                  </Button>{' '}
                  <Button icon={<PrinterOutlined />}>
                    {t('recipe.printButton')}
                  </Button>
                </Space>
              </UserRequiredInfo>
            )
          }
        </AuthGuard>
      </ActionCol>
    </Row>
  );
};
