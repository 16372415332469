import { FunctionComponent, isValidElement, ReactElement } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USER, IResponse } from '../gql/get-user';

type CallbackChild = (isGranted: boolean) => ReactElement | null;

export interface IProps {
  children: Element | ReactElement | CallbackChild | null | boolean;
  roles?: string[];
}

export const AuthGuard: FunctionComponent<IProps> = ({ children, roles }) => {
  const { data, loading, error } = useQuery<IResponse>(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  // Because data is fetched from cache only, this actually never evaluates
  // to true. Therefore, remounts on re-render won't happen.

  if (loading || error || !children) {
    return null;
  }

  // If no user is logged in, isGranted should always be false.
  let isGranted = !!data?.loggedInUser;

  // If one or more roles are given, check if the loggedInUser has any of them.
  if (isGranted && roles && roles.length > 0) {
    isGranted = !!data?.loggedInUser.userRoles.find((ur) =>
      roles.includes(ur.role),
    );
  }

  if (isValidElement(children)) {
    return isGranted ? children : null;
  }

  return (children as CallbackChild)(isGranted);
};
